export default {
    // api请求地址
    // 图片域名
    baseUrl: 'https://store.huamomall.com',
    imgDomain: 'https://store.huamomall.com',
    // baseUrl: 'http://mallv4.com/',
    // imgDomain: 'http://mallv4.com/',
    // 前端域名
    webDomain: "",
    h5Domain: 'https://wap.huamomall.com',
    // 腾讯地图key
    mpKey: "",
    // 客服
    webSocket: "",
    //本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
    pingInterval: 1500
}